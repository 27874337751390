<template>
    <div :style="`direction:${lang.dir}`">
        <v-form
            ref="crmForInv"
            lazy-validation
        >
        <b-sidebar backdrop lazy width="100rem" id="customer_crm" aria-labelledby="sidebar-no-header-title" no-header :style="`direction:`+lang.ldir" right>
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{lang.view_customer}}</span>
                    </div>
                    <div @click="hide" id="HideMeCRM" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="myBtn backBlack" :style="`direction:${lang.dir}`" v-if="$store.state.licenseType.activemenu != 'smallif' || $store.state.group_id != 3">
                    <!-- <div class="btnRow c_darkcyan">{{ lang.add_car }}</div> -->
                    <div class="myBtn" v-if="$store.state.group_id != 5">
                        <div class="btnRow c_darkgreen" @click="showState=true">{{ lang.emp_statment }}</div>
                        <div class="btnRow c_darkblue" @click="addQuot()" v-if="!$store.state.licenseType.isBill" v-b-toggle.add_quotation>{{ lang.add_quotation }}</div>
                        <div class="btnRow c_darkblue" @click="addQuot1()" v-else v-b-toggle.add_BillQuotation>{{ lang.add_quotation }}</div>
                        <div class="btnRow c_darkolivegreen" @click="addTask()" style="" v-if="$store.state.licenseType.booking" v-b-toggle.add_addClientTask>صيانة دورية</div>
                        <div class="btnRow c_darkorange" @click="addCalendar()" style="" v-if="$store.state.licenseType.booking" v-b-toggle.add_addbooking>اضافة عقد</div>
                        <div class="btnRow c_darkorange" @click="addCalendar()" style="" v-if="$store.state.licenseType.booking" v-b-toggle.add_addbooking>الاشتراك</div>
                        <div class="btnRow c_darkorange" @click="addCalendar()" style="" v-if="$store.state.licenseType.booking" v-b-toggle.add_addbooking>{{lang.add_booking}}</div>
                        <div class="btnRow c_green" @click="InvOP()" v-b-toggle.add_invoice>{{ lang.add_invocie }}</div>
                        <div class="btnRow c_darkblue" @click="publish_me()" v-b-toggle.publish_me v-if="$store.state.licenseType.canPublish">{{ lang.PublishMe }}</div>
                        <div class="btnRow c_darkslategrey" @click="resetAllValues()" v-b-toggle.add_new_card v-if="$store.state.licenseType.cars">{{ lang.add_new_card }}</div>
                        <div class="btnRow c_red" @click="addNote()" v-b-toggle.add_crmnote>{{ lang.add_notes }}</div>
                        <div class="btnRow c_darkslateblue" @click="openContract()" v-b-toggle.addContract v-if="$store.state.licenseType.car_contract">{{ lang.add_contract }}</div>
                        <div class="btnRow c_darkorchid" style="display:none;" @click="addTask()" v-if="$store.state.licenseType.cars" v-b-toggle.add_addClientWarranty>{{lang.add_warantly}}</div>
                        <div class="btnRow c_darkorchid" @click="addSupport()" v-b-toggle.add_addHomeTask v-if="$store.state.licenseType.isBill">{{lang.support_ticket}}</div>
                        <div class="btnRow c_red" @click="aupdate()" v-b-toggle.update_customer>{{ lang.update }}</div>
                        <div class="btnRow backGreen" v-b-toggle.send_client_whats style="display:none" @click="openWhatsAPP()">{{lang.whatsapp}}</div>

                        <img :src="`../../whatsappicon.png`" v-b-toggle.send_client_whats style="margin-top:10px;border:2px solid #fff !important;font-size:1.1rem !important;width:120px;margin-inline-start: 10px;height: 38px;border-radius: 5px;cursor:pointer" @click="openWhatsAPP()" />

                        <div class="btnRow c_red" @click="deleteThis()" 
                            v-if="statistic.invoices == 0 && statistic.cards == 0 "
                            >
                            {{ lang.delete }}</div>
                    </div>
                    <div v-else>
                        <div class="btnRow c_red" @click="aupdate()" v-b-toggle.update_customer>{{ lang.update }}</div>
                        <img :src="`../../whatsappicon.png`" v-b-toggle.send_client_whats style="display:none;margin-top:10px;border:2px solid #fff !important;font-size:1.1rem !important;width:120px;margin-inline-start: 10px;height: 38px;border-radius: 5px;cursor:pointer" @click="openWhatsAPP()" />
                    </div>
                </div>
                <div class="myBtn backBlack" :style="`direction:${lang.dir}`" v-if="$store.state.licenseType.activemenu == 'smallif' && $store.state.group_id == 3">
                    <!-- <div class="btnRow c_darkcyan">{{ lang.add_car }}</div> -->
                    <div class="btnRow c_darkblue" @click="addQuot()" v-if="!$store.state.licenseType.isBill" v-b-toggle.add_quotation>{{ lang.add_quotation }}</div>
                    <div class="btnRow c_darkblue" @click="addQuot1()" v-else v-b-toggle.add_BillQuotation>{{ lang.add_quotation }}</div>
                    <div class="btnRow c_blue" @click="aupdate()" v-b-toggle.update_customer>{{ lang.update }}</div>
                    <div class="btnRow c_darkred" v-b-toggle.send_client_whats @click="openWhatsAPP()">{{lang.whatsapp}}</div>
                    <div class="btnRow c_red" @click="deleteThis()" 
                        v-if="statistic.invoices == 0 && statistic.cards == 0 "
                        >
                        {{ lang.delete }}</div>
                </div>
                <div class="mySubBtn" :style="`direction:${lang.dir};margin-inline-start:10px;`">
                    <div class="btnRowF c_black" id="c_cards" @click="Cards('c_cards')">{{ lang.maintenance_cards }} <i class="ms-1 fas fa-arrow-down"></i></div>
                    <div class="btnRowF c_lightgray" id="c_invoices" @click="Invoices('c_invoices')">{{ lang.invoices }} <i class="ms-1 fas fa-arrow-down"></i></div>
                    <div class="btnRowF c_lightgray" id="c_payments" @click="Payments('c_payments')">{{ lang.payments }} <i class="ms-1 fas fa-arrow-down"></i></div>
                    <div class="btnRowF c_lightgray" id="c_quotations" @click="Quots('c_quotations')">{{ lang.quotations }} <i class="ms-1 fas fa-arrow-down"></i></div>
                </div>
                <div v-if="showState" :style="`direction:${lang.dir};margin-inline-start:10px;`">
                    <div class="searchTop" style="margin-inline-end:-20px !important;">
                        <div class="searchBar">
                            <div>
                            <div>{{lang.chose_from_list}} {{lang.start_date}}</div>
                            <b-input-group>
                                <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.day" :options="daysList"/>
                                <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                                <b-form-select class="inborder text-center" style="width:34%;background:#efefef;" v-model="sd.year" :options="yearsList" />
                            </b-input-group>
                            </div>

                            <div>
                                <div>{{lang.chose_from_list}} {{lang.end_date}}</div>
                                <b-input-group>
                                    <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="ed.day" :options="daysList"/>
                                    <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                                    <b-form-select class="inborder text-center" style="width:34%;background:#efefef;" v-model="ed.year" :options="yearsList" />
                                </b-input-group>
                            </div>

                            <div>
                            <div>&nbsp;</div>
                            <b-button variant="light" style="padding-top:2px !important; padding-bottom:2px !important;background:blue;color:#fff;width:120px;"  @click="getStatement()">{{lang.emp_statment}}</b-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="myBtn" :style="`direction:${lang.dir};margin-inline-start:10px;`">
                    <div class="rowDiv">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">{{ lang.customer_info }}</h6>
                        <v-simple-table v-if="customer.executed_date != ''" style="display:none">
                            <thead>
                                <tr>    
                                    <!-- <th class="backBlack" style="width:75px">{{ lang.date }}</th> -->
                                    <!-- <td style="width:100px">{{ customer.executed_date }}</td> -->
                                    <!-- <th class="backBlack" style="width:75px">{{ lang.procedure }}</th> -->
                                    <!-- <td >{{ customer._procedure[`name_${lang.langname}`] }}</td> -->
                                    <!-- <th class="backBlack" style="width:100px">{{ lang.proirity }}</th>
                                    <td  style="width:100px">{{ customer._priority[`name_${lang.langname}`] }}</td> -->
                                    <!-- <th class="backBlack" style="width:75px">{{ lang.agent }}</th> -->
                                    <!-- <td>{{ customer.agent }}</td> -->
                                </tr>
                            </thead>
                        </v-simple-table>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.customerid }}</th>
                                    <td class="text-center" style="min-width:50px;">{{ customer.customerid }}</td>
                                    <th class="text-center backBlack">{{ lang.full_name }}</th>
                                    <td class="text-center" colspan="3">{{ customer.full_name }}</td>
                                </tr>
                                <tr>
                                    <th class="text-center backBlack">{{ lang.customer_type }}</th>
                                    <td class="text-center" :style="`${bgColor}`">{{ _custoemr_type }}</td>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.mobile }}</th>
                                    <td class="text-center" style="min-width:50px;">{{ customer.mobile }}</td>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.mobile }} 2</th>
                                    <td class="text-center" style="min-width:50px;">{{ customer.mobile2 }}</td>
                                </tr>
                                <tr v-if="customer.customer_type == 2">
                                    <th class="text-center backBlack">{{ lang.company_name }}</th>
                                    <td class="text-center" colspan="5">{{ customer.company_name }}</td>
                                </tr>
                                <tr v-if="customer.customer_type == 2">
                                    <th class="text-center backBlack">{{ lang.vat_number }}</th>
                                    <td class="text-center" colspan="2">{{ customer.vatid }}</td>
                                    <th class="text-center backBlack">{{ lang.crt_number }}</th>
                                    <td class="text-center" colspan="2">{{ customer.crt_number }}</td>
                                </tr>
                                <tr v-if="customer.customer_type == 2">
                                    <th class="text-center backBlack">{{ lang.address }}</th>
                                    <td class="text-center" colspan="5">{{ customer.address }}</td>
                                </tr>
                                <tr v-if="customer.customer_type == 3">
                                    <th class="text-center backBlack">{{ lang.entity_name }}</th>
                                    <td class="text-center" colspan="2">{{ customer.entity_name }}</td>
                                    <th class="text-center backBlack">{{ lang.branch_name }}</th>
                                    <td class="text-center" colspan="2">{{ customer.branch_name }}</td>
                                </tr>
                                <!-- <tr>
                                    <th class="text-center backBlack">{{lang.customer_status}}</th>
                                    <td class="text-center" colspan="2">
                                        <b-form-select class="selborder"
                                            v-model="status" @change="changeStatus()"
                                            :options="cusStatus"
                                        />
                                    </td>
                                </tr> -->
                            </thead>
                        </v-simple-table>
                    </div>
                    <div class="rowDiv">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">{{ lang.finance_report }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="width:25%;" v-if="$store.state.licenseType.cars">{{ lang.cars }}</th>
                                    <th class="text-center backBlack" style="width:25%;">{{ lang.invoices }}</th>
                                    <th class="text-center backBlack" style="width:25%;" v-if="$store.state.licenseType.cars">{{ lang.maintenance_cards }}</th>
                                    <th class="text-center backBlack" style="width:25%;">{{ lang.quotations }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center" style="height: 30px !important;" v-if="$store.state.licenseType.cars">{{ statistic.cars }}</td>
                                    <td class="text-center" style="height: 30px !important;">{{ statistic.invoices }}</td>
                                    <td class="text-center" style="height: 30px !important;" v-if="$store.state.licenseType.cars">{{ statistic.cards }}</td>
                                    <td class="text-center" style="height: 30px !important;">{{ statistic.quotations }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <br />
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="width:25%;">{{ lang.invoice_total }}</th>
                                    <th class="text-center backBlack" style="width:25%;">{{ lang.unpaid_job_orders }}</th>
                                    <th class="text-center backBlack" style="width:25%;">{{ lang.paid_amount }}</th>
                                    <th class="text-center backBlack" style="width:25%;">{{ lang.remain }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="height: 30px !important;" class="text-center">{{ $RoundNums(finance.invoices) }}</td>
                                    <td style="height: 30px !important;" class="text-center">{{ $RoundNums(finance.cards,2) }}</td>
                                    <td style="height: 30px !important;border:1px solid #000 !important" class="text-center backGreen">{{ $RoundNums(finance.paid,2) }}</td>
                                    <td style="height: 30px !important;border:1px solid #000 !important" class="text-center backRed">{{ $RoundNums(finance.remain,2) }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull" v-if="licensesAcc.length != 0">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">التجديد السنوي</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.start_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.expire_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.renewal_amount }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.status }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.license_url }}</th>
                                    <th class="text-center backBlack" style="width:80px;">{{ lang.price }}</th>
                                    <th class="text-center backBlack" style="width:80px;">{{ lang.years }}</th>
                                    <th class="text-center backBlack" style="width:80px;">{{ lang.renew }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in licensesAcc" :key="index">
                                    <td class="text-center">{{ item._start_date }}</td>
                                    <td class="text-center">{{ item.exp_date }}</td>
                                    <td class="text-center">{{ $RoundNums(item.renewal_amount) }}</td>
                                    <td class="text-center" :style="`${item._status.style}`">{{ item._status[`name_${lang.langname}`] }}</td>
                                    <td class="text-center">{{ item.domain }}</td>
                                    <td class="text-center" style="width:50px;">
                                        <b-form-input v-model="renewal_price" class="inborder" style="width:80px;" />
                                    </td>
                                    <td class="text-center" style="width:50px;">
                                        <b-form-input v-model="renewal_years" class="inborder" style="width:80px;" />
                                    </td>
                                    <td class="text-center" @click="renewIt(index)" style="cursor:pointer;color:#fff !important;background:green;border-bottom:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">{{ lang.renew }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull" style="display:none;" v-if="$store.state.licenseType.cars && !$store.state.licenseType.activemenu != 'yeserpsam'">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">{{ lang.cars }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.plate_number }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.model }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.car_year }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.car_color }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.vin_number }}</th>
                                    <!-- <th class="text-center backBlack" style="min-width:50px;">{{ lang.invoices }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.unpaid_job_orders }}</th> -->
                                    <!-- <th class="text-center backBlack" style="min-width:50px;">{{ lang.action }}</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in cars" :key="index">
                                    <td class="text-center">{{ item.plate_number }}</td>
                                    <td class="text-center">{{ item.model }}</td>
                                    <td class="text-center">{{ item.car_year }}</td>
                                    <td class="text-center">{{ item.color }}</td>
                                    <td class="text-center">{{ item.vin_number }}</td>
                                    <!-- <td class="text-center">{{ item.invoices }}</td>
                                    <td class="text-center">{{ item.cards }}</td> -->
                                    <!-- <td class="text-center" style="color:#fff !important;background:blue;border-bottom:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">{{ lang.report }}</td> -->
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull" v-if="currentSales.length != 0">
                        <h6 style="font--weight: 700;background:black;color:#fff;padding:5px;text-align:center;">{{ lang.sales }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="min-width:70px;">الاجراء المطلوب</th>
                                    <th class="text-center backBlack" style="min-width:70px;">تاريخ الاتصال</th>
                                    <th class="text-center backBlack" style="min-width:70px;">{{ lang.time }}</th>
                                    <th class="text-center backBlack" style="min-width:70px;">{{ lang.agent }}</th>
                                    <th class="text-center backBlack" style="min-width:70px;">{{ lang.source }}</th>
                                    <th class="text-center backBlack">{{ lang.notes }}</th>
                                    <th class="text-center backBlack" style="min-width:70px;">{{ lang.product }}</th>
                                    <th class="text-center backBlack" style="min-width:70px;">ما تم اجراؤه</th>
                                    <th class="text-center backBlack" style="min-width:70px;">الحالة</th>
                                    <th class="text-center backBlack" style="min-width:70px;">{{ lang.action }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in currentSales" :key="index">
                                    <td class="text-center" :style="`${item[`procedure_${item.procedure_id}`].style}`">{{ item[`procedure_${item.procedure_id}`][`name_${lang.langname}`] }}</td>
                                    <td class="text-center">{{ item.executed_date }}</td>
                                    <td class="text-center">{{ item.executed_time }}</td>
                                    <td class="text-center">{{ item.agent }}</td>
                                    <td class="text-center" :style="`${item.source.style}`">{{ item.source[`name_${lang.langname}`] }}</td>
                                    <td class="text-center">{{ item.notes }}</td>
                                    <td class="text-center" :style="`${item.service_.style}`">{{ item.service_[`name_${lang.langname}`] }}</td>
                                    <td class="text-center" :style="`${item.service_status.style}`">{{ item.service_status[`name_${lang.langname}`] }}</td>
                                    <td class="text-center" >{{item.final_status}}</td>
                                    <td class="text-center backRed" style="background:red !important;cursor:pointer" @click="openSales(item)" v-b-toggle.editHomeSales>{{ lang.update }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">{{ lang.invoices }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.invoiceno }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.inv_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.added_time }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.invoice_type }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;" v-if="$store.state.licenseType.carinfo">{{ lang.plate_number }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;" v-if="$store.state.licenseType.carinfo">{{ lang.car_model }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.total }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.vat }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.ftotal }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.paid_amount }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.remain }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.status }}</th>
                                    <th class="text-center backBlack" style="width:50px;">{{ lang.print }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in invoices" :key="index">
                                    <td class="text-center">{{ item.invoice_number }}</td>
                                    <td class="text-center">{{ item.inv_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.inv_date.substring(11) }}</td>
                                    <td class="text-center">{{ item[`_invtype_`+lang.langname] }}</td>
                                    <td class="text-center" v-if="$store.state.licenseType.carinfo">{{ item.plate_number }}</td>
                                    <td class="text-center" v-if="$store.state.licenseType.carinfo">{{ item.model }}</td>
                                    <td class="text-center">{{ $convertToComa(item.total,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.vat,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.ftotal,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.paid,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.remain,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center" :style="`width:80px;background:${item.bgColor};color:${item.color};border:1px solid #bbb !important;`">{{ item[`_status_`+lang.langname] }}</td>
                                    <td class="text-center" @click="printIt(1,item.id)" style="cursor:pointer;color:#fff !important;background:blue !important;border:2px solid #bbb !important;width:80px;border-top:2px solid #fff !important">
                                        <div>{{ lang.print }}</div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty" v-if="$store.state.licenseType.carinfo"></td>
                                <td class="backEmpty" v-if="$store.state.licenseType.carinfo"></td>
                                <td class="backBlack text-center">{{ lang.total }}</td>
                                <td class="backGreen text-center">{{ totlas.total }}</td>
                                <td class="backRed text-center">{{ totlas.vat }}</td>
                                <td class="backGreen text-center">{{ totlas.ftotal }}</td>
                                <td class="backGreen text-center">{{ totlas.paid }}</td>
                                <td class="backRed text-center">{{ totlas.remain }}</td>
                                <td class="backEmpty"></td>
                                <!-- <td class="backEmpty"></td> -->
                            </tfoot>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull" v-if="$store.state.licenseType.cars">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">{{ lang.mentanace_card }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.cardid }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.received }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.delivered }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.total }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.vat }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.ftotal }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.paid_amount }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.remain }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.status }}</th>
                                    <!-- <th class="text-center backBlack" style="width:50px;">{{ lang.make_payment }}</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in cards" :key="index">
                                    <td class="text-center">{{ item.card_id }}</td>
                                    <td class="text-center">{{ item.received_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.delivery_date != '' ? item.delivery_date.substring(0,10) : '-' }}</td>
                                    <td class="text-center">{{ $convertToComa(item.total,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.vat,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.ftotal,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.paid,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.remain,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center" :style="`width:80px;background:${item.bgColor} !important;color:${item.color} !important;border:1px solid #bbb !important;`">{{ item._status }}</td>
                                    <!-- <td class="text-center" style="color:#fff !important;background:darkgreen;border:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                        <div v-if="item.remain !=0">{{ lang.make_payment }}</div>
                                        <div v-else></div>
                                    </td> -->
                                </tr>
                            </tbody>
                            <tfoot>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backBlack text-center">{{ lang.total }}</td>
                                <td class="backGreen text-center">{{ ctotlas.total }}</td>
                                <td class="backRed text-center">{{ ctotlas.vat }}</td>
                                <td class="backGreen text-center">{{ ctotlas.ftotal }}</td>
                                <td class="backGreen text-center">{{ ctotlas.paid }}</td>
                                <td class="backRed text-center">{{ ctotlas.remain }}</td>
                                <td class="backEmpty"></td>
                                <!-- <td class="backEmpty"></td> -->
                            </tfoot>
                        </v-simple-table>
                    </div>
                    <div class="rowDiv">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">{{ lang.quotations }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.quotation_no }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.quot_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.ftotal }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.status }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.print }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in quotes" :key="index">
                                    <td class="text-center">{{ item.quot_number }}</td>
                                    <td class="text-center">{{ item.quot_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ $convertToComa(item.ftotal,$store.state.licenseType.moneyComma) }}</td>
                                    <td class="text-center" :style="`width:80px;background:${item.bgColor};color:${item.color};border:2px solid #FFF !important;`">{{ item[`_status_`+lang.langname] }}</td>
                                    <td class="text-center" @click="printIt(2,item.id)" style="cursor:pointer;color:#fff !important;background:blue !important;border:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                        <div>{{ lang.print }}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDiv">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">{{ lang.notes }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.added_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.notes }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.added_by }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in notes" :key="index">
                                    <td class="text-center">{{ item.created_date }}</td>
                                    <td class="text-start" style="white-space: break-spaces;">{{ item.notes }}</td>
                                    <td class="text-center">{{ item.created_by }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull" v-if="$store.state.licenseType.activemenu != 'yeserpsam'">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">{{ lang.payments }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.voutcher_no }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.added_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.paytype }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.ftotal }}</th>
                                    <!-- <th class="text-center backBlack" style="min-width:50px;">{{ lang.print }}</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in payments" :key="index">
                                    <td class="text-center">{{ item.voutcherid }}</td>
                                    <td class="text-center">{{ item.receipt_date }}</td>
                                    <td class="text-center">{{ item[`paytype_${lang.langname}`] }}</td>
                                    <td class="text-center">{{ item.ftotal }}</td>
                                    <!-- <td class="text-center" style="color:#fff !important;background:blue;border-bottom:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">{{ lang.print }}</td> -->
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull" style="display:none;">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">{{ lang.tasks }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="min-width:50px;">#</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.title }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.task_to }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.start_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.dead_line }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.added_by }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.added_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.last_update_by }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.last_update_date}}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.closed_by}}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.closed_date}}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.status }}</th>
                                    <th class="text-center backBlack" style="width:50px;">{{ lang.view }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in tasks" :key="index">
                                    <td class="text-center">{{ item.id }}</td>
                                    <td class="text-center">{{ item.title }}</td>
                                    <td class="text-center">{{ item.task_to }}</td>
                                    <td class="text-center">{{ item.start_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.deadline.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.added_by }}</td>
                                    <td class="text-center">{{ item.created_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.last_update_by }}</td>
                                    <td class="text-center">{{ item.last_update.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.closed_by }}</td>
                                    <td class="text-center">{{ item.closed_date.substring(0,10) }}</td>
                                    <td class="text-center" :style="`width:80px;background:${item._status_bgColor} !important;color:${item._status_Color} !important;border:2px solid #FFF !important;`">{{ item[`_status_`+lang.langname] }}</td>
                                    <td class="text-center" @click="editTask(item)" v-b-toggle.update_task style="cursor:pointer;color:#fff !important;background:blue;border:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                        {{lang.view}}
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div class="rowDivFull" v-if="$store.state.licenseType.cars3">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">{{ lang.add_warantly }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="min-width:50px;">#</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.plate_number }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.task_to }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.start_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.dead_line }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.added_by }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.added_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.last_update_by }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.last_update_date}}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.closed_by}}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.closed_date}}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.status }}</th>
                                    <!-- <th class="text-center backBlack" style="width:50px;">{{ lang.view }}</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in warrantly" :key="index">
                                    <td class="text-center">{{ item.id }}</td>
                                    <td class="text-center">{{ item.plate_number }}</td>
                                    <td class="text-center">{{ item.task_to }}</td>
                                    <td class="text-center">{{ item.start_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.deadline.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.added_by }}</td>
                                    <td class="text-center">{{ item.created_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.last_update_by }}</td>
                                    <td class="text-center">{{ item.last_update.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.closed_by }}</td>
                                    <td class="text-center">{{ item.closed_date.substring(0,10) }}</td>
                                    <td class="text-center" :style="`width:80px;background:${item._status_bgColor} !important;color:${item._status_Color} !important;border:2px solid #FFF !important;`">{{ item[`_status_`+lang.langname] }}</td>
                                    <!-- <td class="text-center" style="color:#fff !important;background:darkgreen;border:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                        <div v-if="item.remain !=0">{{ lang.action }}</div>
                                        <div v-else></div>
                                    </td> -->
                                </tr>
                            </tbody>
                        </v-simple-table>                        
                    </div>
                    <div class="rowDivFull" v-if="$store.state.licenseType.showBooking">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">{{ lang.book_calendar }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="min-width:50px;">#</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.task_to }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.start_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.start_time }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.added_by }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.added_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.last_update_by }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.last_update_date}}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.closed_by}}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.closed_date}}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.status }}</th>
                                    <!-- <th class="text-center backBlack" style="width:50px;">{{ lang.view }}</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in calendar" :key="index">
                                    <td class="text-center">{{ item.id }}</td>
                                    <td class="text-center">{{ item.task_to }}</td>
                                    <td class="text-center">{{ item.start_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.start_time }}</td>
                                    <td class="text-center">{{ item.added_by }}</td>
                                    <td class="text-center">{{ item.created_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.last_update_by }}</td>
                                    <td class="text-center">{{ item.last_update.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.closed_by }}</td>
                                    <td class="text-center">{{ item.closed_date.substring(0,10) }}</td>
                                    <td class="text-center" :style="`width:80px;background:${item._status_bgColor} !important;color:${item._status_Color} !important;border:2px solid #FFF !important;`">{{ item[`_status_`+lang.langname] }}</td>
                                    <!-- <td class="text-center" style="color:#fff !important;background:darkgreen;border:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                        <div v-if="item.remain !=0">{{ lang.action }}</div>
                                        <div v-else></div>
                                    </td> -->
                                </tr>
                            </tbody>
                        </v-simple-table>                        
                    </div>
                    <div class="rowDivFull" v-if="$store.state.licenseType.showPeriodMaint">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">{{ lang.period_maint }}</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="min-width:50px;">#</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.task_to }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.start_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.start_time }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.added_by }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.added_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.last_update_by }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.last_update_date}}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.closed_by}}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.closed_date}}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.status }}</th>
                                    <!-- <th class="text-center backBlack" style="width:50px;">{{ lang.view }}</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in periodCalendar" :key="index">
                                    <td class="text-center">{{ item.id }}</td>
                                    <td class="text-center">{{ item.task_to }}</td>
                                    <td class="text-center">{{ item.start_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.start_time }}</td>
                                    <td class="text-center">{{ item.added_by }}</td>
                                    <td class="text-center">{{ item.created_date.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.last_update_by }}</td>
                                    <td class="text-center">{{ item.last_update.substring(0,10) }}</td>
                                    <td class="text-center">{{ item.closed_by }}</td>
                                    <td class="text-center">{{ item.closed_date.substring(0,10) }}</td>
                                    <td class="text-center" :style="`width:80px;background:${item._status_bgColor} !important;color:${item._status_Color} !important;border:2px solid #FFF !important;`">{{ item[`_status_`+lang.langname] }}</td>
                                    <!-- <td class="text-center" style="color:#fff !important;background:darkgreen;border:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                        <div v-if="item.remain !=0">{{ lang.action }}</div>
                                        <div v-else></div>
                                    </td> -->
                                </tr>
                            </tbody>
                        </v-simple-table>                        
                    </div>
                    <div class="rowDivFull" v-if="$store.state.licenseType.showSubscriber">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">الاشتراكات</h6>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="min-width:50px;">#</th>
                                    <th class="text-center backBlack" style="min-width:50px;">اسم البرنامج</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.start_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.expire_date }}</th>
                                    <th class="text-center backBlack" style="min-width:50px;">{{ lang.status }}</th>
                                    <!-- <th class="text-center backBlack" style="width:50px;">{{ lang.view }}</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in Subscriber" :key="index">
                                    <td class="text-center">{{ item.id }}</td>
                                    <td class="text-center">{{ item.program_name }}</td>
                                    <td class="text-center">{{ item.start_date }}</td>
                                    <td class="text-center">{{ item.expire_date }}</td>
                                    <td class="text-center" :style="`width:80px;background:${item._status_bgColor} !important;color:${item._status_Color} !important;border:2px solid #FFF !important;`">{{ item[`_status_`+lang.langname] }}</td>
                                    <!-- <td class="text-center" style="color:#fff !important;background:darkgreen;border:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                        <div v-if="item.remain !=0">{{ lang.action }}</div>
                                        <div v-else></div>
                                    </td> -->
                                </tr>
                            </tbody>
                        </v-simple-table>                        
                    </div>
                    <div class="rowDivFull" v-if="$store.state.licenseType.car_contract && $store.state.licenseType.activemenu != 'yeserpsam'" >
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">{{ lang.contracts }}</h6>
                        <v-data-table
                            :headers="conheaders"
                            :items="contracts"
                            :items-per-page="15"
                            width="100%"
                            id="MainTable"
                            hide-default-footer
                            fixed-header
                        >
                            <template v-slot:item="row">
                                <tr>
                                    <td style="width:60px">{{ row.item.id }}</td>
                                    <td>{{ row.item.mobile }}</td>
                                    <td>{{ row.item.company_name }}</td>
                                    <td style="width:60px">{{ row.item.last_metinance_date }}</td>
                                    <td nowrap class="text-center">{{ row.item.last_metinance_by }}</td>
                                    <td nowrap class="text-center" style="width:80px;">{{ row.item.cars.length }}</td>
                                    <td nowrap style="width:80px;">{{ row.item.total }}</td>
                                    <td style="width:80px;" >{{ row.item.vat }}</td>
                                    <td style="width:80px;" >{{ row.item.ftotal }}</td>
                                    <!-- <td style="margin-left: 5px;width:80px;background:blue;border-bottom:2px solid #fff !important;border-top:2px solid #fff !important;">
                                        <div class="viewBTN" @click="openView(row.item.id)" v-b-toggle.car_contract_view>{{lang.view}}</div>
                                    </td> -->
                                </tr>
                            </template>
                            
                        </v-data-table>
                    </div>

                    <div class="rowDivFull" v-if="$store.state.licenseType.carinfo">
                        <h6 style="background:#e7e7e7;font-weight: 700;color:#000;padding:5px;text-align:center;">استلام السيارات</h6>
                        <v-data-table
                            :headers="conheaders"
                            :items="checkUpItems"
                            :items-per-page="15"
                            width="100%"
                            id="MainTable"
                            hide-default-footer
                            fixed-header
                        >
                            <template v-slot:item="row">
                                <tr>
                                    <td style="width:50px;text-align:center;">{{ row.item.count }}</td>
                                    <td style="width:50px;text-align:center;">{{ row.item.id }}</td>
                                    <td style="width:50px;text-align:center;">{{ row.item.created_date_ }}</td>
                                    <td class="text-center" nowrap style="text-align:center;">{{ row.item.branch }}</td>
                                    <td class="text-center" nowrap style="text-align:center;">{{ row.item.customer_name }}</td>
                                    <td class="text-center" nowrap style="text-align:center;">{{ row.item.owner_name }}</td>
                                    <td class="text-center" nowrap style="text-align:center;">{{ row.item.mobile }}</td>
                                    <td class="text-center" nowrap style="text-align:center;">{{ row.item.owner_mobile_number }}</td>
                                    <td style="width:100px;text-align:center;">{{ row.item.plate_number }}</td>
                                    <td style="width:100px;text-align:center;">{{ row.item.model }}</td>
                                    <td :style="`width:80px;text-align:center;`">{{ row.item.po_number }}</td>
                                    <td :style="`width:80px;text-align:center;`">{{ row.item.status }}</td>
                                    <td :style="`width:80px;text-align:center;`">{{ row.item.status }}</td>
                                    <td class="text-center" v-if="$store.state.group_id == 1" @click="delCheckup(item.id)" style="cursor:pointer;color:#fff !important;background:red;border:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                                        {{lang.delete}}
                                    </td>
                                </tr>
                            </template>
                            
                        </v-data-table>
                    </div>
                    
                </div>
            </template>
        </b-sidebar>
        </v-form>
        <UpdateCustomer ref="updatecustomer" />
        <addClientNote ref="clientNotes" />
        <addInvoice ref="addinvoices" />
        <AddQuotation ref="AddQuotation" />
        <addNewCard ref="createnew" />
        <addClientTask ref="add_addClientTask" />
        <addClientWarranty ref="add_addClientWarranty" />
        <addCalendar ref="addCalendar" />
        <addHomeSupport ref="addHomeSupport" />
        <addContract ref="addContract" />
        <UpdateSamTask ref="UpdateSamTask" />
        <UpdateProcedures ref="UpdateProcedures" />
        <PublishMe ref="PublishMe" />
        <sendClientWhats ref="sendClientWhats" />
        <editHomeSales ref="editHomeSales" />
        <addBillQuotation ref="addBillQuotation" />
    </div>
</template>

<script>
import axios from 'axios'
import addClientNote from '@/components/addClientNotes.vue'
import addInvoice from '@/components/addInvoice.vue'
import AddQuotation from '@/components/addQuotation.vue'
import addNewCard from "@/components/addNewCard.vue"
import UpdateCustomer from '@/components/update-customer.vue'
import addClientTask from '@/components/addClientTask.vue'
import addClientWarranty from '@/components/addClientWarranty.vue'
import addCalendar from '@/components/addCalendar.vue'
import addHomeSupport from '@/components/addHomeSupport.vue'
import addContract from '@/components/addContract.vue'
import {SnotifyPosition} from 'vue-snotify';
import UpdateSamTask from '@/components/UpdateSamTask.vue';
import UpdateProcedures from '@/components/UpdateProcedures.vue';
import PublishMe from '@/components/publish_me.vue';
import sendClientWhats from '@/components/sendClientWhats.vue';
import editHomeSales from '@/components/editHomeSales.vue';
import addBillQuotation from '@/components/addBillQuotation.vue';
export default {
    components: {addBillQuotation,editHomeSales,sendClientWhats,PublishMe,UpdateProcedures,UpdateSamTask,addHomeSupport,addClientNote,addInvoice,AddQuotation,addNewCard,UpdateCustomer,addClientTask,addClientWarranty,addCalendar,addContract},
    data() {
        return {
            sd: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            showState: false,
            checkUpItems: [],
            renewal_price: '',
            renewal_years: 1,
            licensesAcc: [],
            currentSales: [],
            contracts: [],
            customerid: 0,
            tasks: [],
            fineReq: [
                {
                    invoice_number: 1,
                    created_date: '2023-04-10',
                    amount: 1500,
                    created_by: 'Admin',
                },
                {
                    invoice_number: 1,
                    created_date: '2023-04-10',
                    amount: 1500,
                    created_by: 'Admin',
                },
                {
                    invoice_number: 1,
                    created_date: '2023-04-10',
                    amount: 1500,
                    created_by: 'Admin',
                },
                {
                    invoice_number: 1,
                    created_date: '2023-04-10',
                    amount: 1500,
                    created_by: 'Admin',
                },
            ],
            notes: [],
            quotes:[],
            cards:[],
            invoices: [],
            cars:[],
            payments: [],
            statistic: {
                cars: 0,
                invoices: 0,
                cards: 0,
                quotations: 0,
            },
            finance: {
                invoices: 0,
                cards: 0,
                paid: 0,
                remain: 0
            },
            customer: {
                executed_date: '',
                _priority: {
                    name_ar: '-',
                    name_en: '-',
                    style: ''
                },
                _procedure: {
                    name_ar: '-',
                    name_en: '-',
                    style: ''
                }
            },
            warrantly: [],
            cusStatus: [],
            calendar: [],
            periodCalendar: [],
            Subscriber: [],
            // contact_agent: 1,
            // procedure: 1,
            // client_priority: 1,
            // exeute_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        yearsList: function(){
            const start_year = this.$store.state.licenseType.startYear;
            let t = [{text: this.lang.year, value: -1}];
            for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
                t.push({text: start_year + i, value: start_year+i})
            }
            
            }
            return t
        },
        daysList: function(){
            return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
            ]
        },
        monthsList: function(){
            return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            ]
        },
        receivedCArs: function(){
            let t = [
                { 
                    text: "#", align: 'center',
                    filterable: false, value: 'id',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: "المعرف", align: 'center',
                    filterable: false, value: 'id',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: "تاريخ الاستلام", align: 'center',
                    filterable: false, value: 'received_date',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.branch_name, align: 'center',
                    filterable: false, value: 'company_name',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.company_name, align: 'center',
                    filterable: false, value: 'company_name',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.owner_name, align: 'center',
                    filterable: false, value: 'owner_name',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.mobile, align: 'center',
                    filterable: false, value: 'mobile',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.owner_mobile_number, align: 'center',
                    filterable: false, value: 'owner_mobile_number',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.plate_number, align: 'center',
                    filterable: false, value: 'plate_number',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.car_model, align: 'center',
                    filterable: false, value: 'model',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.po_number, align: 'center',
                    filterable: false, value: 'status',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.status, align: 'center',
                    filterable: false, value: 'status',
                    sortable: false,class: 'backBlack'
                }
            ]

            return t;
        },
        conheaders: function() {
            let t = [
                {
                    text: "#",
                    align: 'center',
                    filterable: false,
                    value: 'contractid',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: "رقم الاستلام",
                    align: 'center',
                    filterable: false,
                    value: 'contractid',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: "تاريخ الاستلام",
                    align: 'center',
                    filterable: false,
                    value: 'mobile',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: "الفرع",
                    align: 'center',
                    filterable: false,
                    value: 'customer_name',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: "اسم العميل",
                    align: 'center',
                    filterable: false,
                    value: 'project_type',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: "اسم المالك",
                    align: 'center',
                    filterable: false,
                    value: 'last_maint_by',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: "رقم العميل",
                    align: 'center',
                    filterable: false,
                    value: 'city',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: "رقم اتصال العميل",
                    align: 'center',
                    filterable: false,
                    value: 'total',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: this.lang.plate_number,
                    align: 'center',
                    filterable: false,
                    value: 'vat',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: this.lang.car_model,
                    align: 'center',
                    filterable: false,
                    value: 'ftotal',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: this.lang.po_number,
                    align: 'center',
                    filterable: false,
                    value: 'ftotal',
                    sortable: false,
                    class: 'pa-0'
                },
                {
                    text: this.lang.status,
                    align: 'center',
                    filterable: false,
                    value: 'ftotal',
                    sortable: false,
                    class: 'pa-0'
                },
            ];
            if(this.$store.state.group_id == 1){
                t.push({
                    text: this.lang.delete,
                    align: 'center',
                    filterable: false,
                    value: 'ftotal',
                    sortable: false,
                    class: 'pa-0'
                })
            }
            return t;
        },
        totlas: function(){
            let t = {
                total: 0,
                vat: 0,
                ftotal: 0,
                paid:0,
                remain: 0
            }
            for(let i=0;i<this.invoices.length;i++){
                t.total = parseFloat(t.total) + parseFloat(this.invoices[i].total)
                t.vat = parseFloat(t.vat) + parseFloat(this.invoices[i].vat)
                t.ftotal = parseFloat(t.ftotal) + parseFloat(this.invoices[i].ftotal)
                t.paid = parseFloat(t.paid) + parseFloat(this.invoices[i].paid)
                t.remain = parseFloat(t.remain) + parseFloat(this.invoices[i].remain)
            }
            // $convertToComa(item.total,$store.state.licenseType.moneyComma)
            return {
                total: this.$convertToComa(this.$RoundNum(t.total,2),this.$store.state.licenseType.moneyComma),
                vat: this.$convertToComa(this.$RoundNum(t.vat,2),this.$store.state.licenseType.moneyComma),
                ftotal: this.$convertToComa(this.$RoundNum(t.ftotal,2),this.$store.state.licenseType.moneyComma),
                paid: this.$convertToComa(this.$RoundNum(t.paid,2),this.$store.state.licenseType.moneyComma),
                remain: this.$convertToComa(this.$RoundNum(t.remain,2),this.$store.state.licenseType.moneyComma)
            };
        },
        ctotlas: function(){
            let t = {
                total: 0,
                vat: 0,
                ftotal: 0,
                paid:0,
                remain: 0
            }
            for(let i=0;i<this.cards.length;i++){
                t.total = parseFloat(t.total) + parseFloat(this.cards[i].total)
                t.vat = parseFloat(t.vat) + parseFloat(this.cards[i].vat)
                t.ftotal = parseFloat(t.ftotal) + parseFloat(this.cards[i].ftotal)
                t.paid = parseFloat(t.paid) + parseFloat(this.cards[i].paid)
                t.remain = parseFloat(t.remain) + parseFloat(this.cards[i].remain)
            }
            // $convertToComa(item.total,$store.state.licenseType.moneyComma)
            return {
                total: this.$convertToComa(this.$RoundNum(t.total,2),this.$store.state.licenseType.moneyComma),
                vat: this.$convertToComa(this.$RoundNum(t.vat,2),this.$store.state.licenseType.moneyComma),
                ftotal: this.$convertToComa(this.$RoundNum(t.ftotal,2),this.$store.state.licenseType.moneyComma),
                paid: this.$convertToComa(this.$RoundNum(t.paid,2),this.$store.state.licenseType.moneyComma),
                remain: this.$convertToComa(this.$RoundNum(t.remain,2),this.$store.state.licenseType.moneyComma)
            };
        },
        _custoemr_type: function(){
            let t = this.lang.individual_client
            if(this.customer.customer_type == 2){
                t = this.lang.company_clinet
            }
            if(this.customer.customer_type == 3){
                t = this.lang.government_clinet
            }
            return t;
        },
        bgColor: function(){
            let t = 'background: #335801 !important;color:#fff !important'
            if(this.customer.customer_type == 2){
                t = 'background: darkblue !important;color:#fff !important'
            }
            if(this.customer.customer_type == 3){
                t = 'background: #404258 !important;color:#fff !important'
            }
            return t;
        },

    },
    methods:{
        getStatement() {
            this.showState = false;
            let day = (this.sd.day < 10 ? "0" : "") + this.sd.day;
            let month = (this.sd.day < 10 ? "0" : "") + this.sd.month;
            const sdate = this.sd.year + "-" + month + '-' + day

            day = (this.ed.day < 10 ? "0" : "") + this.ed.day;
            month = (this.ed.day < 10 ? "0" : "") + this.ed.month;
            const edate = this.ed.year + "-" + month + '-' + day

            window.open(`./api/print.php?st=${this.customerid}&sdate=${sdate}&edate=${edate}`, "_blank");
        },
        delCheckup(id){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","delCheckup");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
            const message = this.lang.deleted_successfuly
            this.$snotify.error(message, this.lang.alert, {
                timeout: 1000000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                position: SnotifyPosition.centerCenter,
                buttons: [
                    {
                        text: this.lang.close, 
                        action: (toast) => {
                            this.$snotify.remove(toast.id); 
                        } 
                    },
                ]
            });
            this.getCheckup();
            });
            
        },
        getCheckup() {
        const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getCurrentCheckup");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[customerId]',this.customerid);
            
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.checkUpItems = response.data.results.data;
            });
            
        },
        printIt(type,id)
        {
            if(type == 1){
                window.open("./api/print.php?invid="+id,"_blank")
            }else if(type == 2){
                window.open("./api/print.php?quotid="+id,"_blank")
            }
        },
        renewIt(index)
        {
            if(this.renewal_price == ''){
                this.renewal_price = this.licensesAcc[index].renewal_amount;
            }
            if(this.renewal_years == '' || this.renewal_years == 0){
                this.renewal_years = 1;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear')); 
            post.append("type","renewMe");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[id]",this.licensesAcc[index].id)
            post.append("data[renewal_price]",this.renewal_price)
            post.append("data[years]",this.renewal_years)
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data){
                    this.getCustomerInfo();
                }
            })
        },
        openSales(item){
            this.$refs.editHomeSales.id = item.id;
            this.$refs.editHomeSales.getSales();
            this.$refs.editHomeSales.getTimes();
        },
        getSales(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear')); 
            post.append("type","getCustomerSales");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[customer_id]",this.customerid)
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data){
                    this.currentSales = response.data.results.data;
                }
            })
        },
        openWhatsAPP(){
            this.$refs.sendClientWhats.number_list = this.customer.mobile
            this.$refs.sendClientWhats.getOldMessages();
        },
        publish_me(){
            this.$refs.PublishMe.customerid = this.customer.customerid
        },
        aupProcedures(){
            const exDate = (new Date(this.customer.exeute_date)).toISOString().substr(0, 10);
            // // console.log(exDate);
            // console.log(this.customer.exeute_date);
            this.$refs.UpdateProcedures.crm_id = this.customer.id
            this.$refs.UpdateProcedures.status = this.customer.status
            this.$refs.UpdateProcedures.procedure = this.customer.procedure
            this.$refs.UpdateProcedures.client_priority = this.customer.client_priority
            this.$refs.UpdateProcedures.contact_agent = this.customer.contact_agent
            this.$refs.UpdateProcedures.exeute_date = exDate
            this.$refs.UpdateProcedures.notes = ""
            // this.$refs.UpdateProcedures.consoleMe()
        },
        editTask(item) {
            this.$refs.UpdateSamTask.crm_id = item.id;
            this.$refs.UpdateSamTask.title = item.title;
            this.$refs.UpdateSamTask.description = item.description;
            this.$refs.UpdateSamTask.task_response = item.userid;
            this.$refs.UpdateSamTask.status = item.status;
            this.$refs.UpdateSamTask.notes = '';
            this.$refs.UpdateSamTask.getTaskDet();
            this.$refs.UpdateSamTask.show()
                
        },
        openView(id){
            this.$refs.CarContractView.getContracts (id);
        },
        getContracts(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear')); 
            post.append("type","getCarContracts");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[customerid]",this.customerid)
            
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data){
                    this.contracts = response.data.results.data;
                }
            })
        },
        addSupport(){
            this.$refs.addHomeSupport.mobile = this.customer.mobile;
            this.$refs.addHomeSupport.getCustoemrs(2)
        },
        deleteThis(){
            if(this.customerid == 0) return false;
            if(this.statistic.invoices != 0 || this.statistic.cards != 0) return false;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","deleteCustomer")
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[customerid]",this.customerid)
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    if(response.data.error.number != 200){
                        ///
                    }else{
                        this.$parent.getClients();
                        document.getElementById('HideMeCRM').click();
                    }

                    let message = this.lang[response.data.error.message];
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    
                }
            })
        },
        aupdate(){
            this.$refs.updatecustomer.customers = {
                id: this.customer.id,
                customerid: this.customer.customerid,
                mobile: this.customer.mobile,
                mobile1: typeof this.customer !== 'undefined' ? this.customer.mobile2 : '',
                full_name: this.customer.full_name,
                company_name: this.customer.company_name,
                company_vatid: this.customer.company_vatid,
                building_no: this.customer.building_no,
                street_name: this.customer.street_name,
                branch_number: this.customer.branch_number,
                district: this.customer.district,
                zipcode: this.customer.zipcode,
                city:this.customer.city,
                address:this.customer.address,
                customer_type: this.customer.customer_type,
                entity_name: this.customer.entity_name,
                branch_name: this.customer.branch_name,
                exeute_date: this.customer.exeute_date,
                contact_agent: this.customer.contact_agent,
                procedure: this.customer.procedure,
                client_priority: this.customer.client_priority,
                // crt_number: ''
            }
            this.$refs.updatecustomer.getCustomer();
            this.$refs.updatecustomer.changeCusType(parseInt(this.customer.customer_type) - 1)
        },
        async resetAllValues(){
            // this.$refs.createnew.resetValues();
            await this.$refs.createnew.reset();
            await this.$refs.createnew.getInvSettings();
            this.$refs.createnew.customer.cctype = this.customer.customer_type
            this.$refs.createnew.customer.customer_type = this.customer.customer_type
            this.$refs.createnew.customer.customerid = this.customer.id;
            this.$refs.createnew.customer.id = this.customer.id;
            this.$refs.createnew.showClientTypeRow = false;
            // if(this.customer.customer_type == 2){
            //     this.$refs.createnew.changeCusType(1);
            // }else if(this.customer.customer_type == 3){
            //     this.$refs.createnew.changeCusType(2);
            // }
            // else{
            //     this.$refs.createnew.changeCusType(0);
            // }
            this.$refs.createnew.getName();
            // this.$refs.createnew.$refs.firstCar.image = '';
        },
        addQuot(){
            this.$refs.AddQuotation.reset();
            this.$refs.AddQuotation.customer.id = this.customer.id;
            this.$refs.AddQuotation.customer.mobile = this.customer.mobile;
            this.$refs.AddQuotation.customer.cctype = this.customer.customer_type
            this.$refs.AddQuotation.customer.customer_type = this.customer.customer_type
            if(this.customer.customer_type == 1){
                this.$refs.AddQuotation.showcamp = false;
                this.$refs.AddQuotation.showGov = false;
            }
            else if(this.customer.customer_type == 2){
                this.$refs.AddQuotation.showcamp = true;
                this.$refs.AddQuotation.showGov = false;
            }
            else if(this.customer.customer_type == 3){
                this.$refs.AddQuotation.showcamp = false;
                this.$refs.AddQuotation.showGov = true;
            }
            this.$refs.AddQuotation.getName();
            this.$refs.AddQuotation.showClientTypeRow = false;

            // if(this.customer.customer_type == 2){
            //     this.$refs.AddQuotation.changeCusType(1);
            // }else if(this.customer.customer_type == 3){
            //     this.$refs.AddQuotation.changeCusType(2);
            // }
            // else{
            //     this.$refs.AddQuotation.changeCusType(0);
            // }
            // this.$refs.AddQuotation.getName();
        },
        addQuot1(){
            this.$refs.addBillQuotation.reset(this.customer)
        },
        InvOP(){
            // console.log(this.customer);
            this.$refs.addinvoices.getNewInvoiceID();
            this.$refs.addinvoices.reset();
            this.$refs.addinvoices.customer.id = this.customerid;
            this.$refs.addinvoices.customer.mobile = this.customer.mobile;
            this.$refs.addinvoices.customer.customerid = this.customerid;
            this.$refs.addinvoices.showClientTypeRow = false;
            if(this.customer.customer_type == 2){
                this.$refs.addinvoices.changeCusType(1);
            }else if(this.customer.customer_type == 3){
                this.$refs.addinvoices.changeCusType(2);
            }
            else{
                this.$refs.addinvoices.changeCusType(0);
            }
            this.$refs.addinvoices.getAllNameById(0);
        },
        addCalendar(){

            this.$refs.addCalendar.task_description = "";
            this.$refs.addCalendar.customerid = this.customerid;
        },
        openContract(){
            this.$refs.addContract.resetAll ();
            this.$refs.addContract.mobile = this.customer.mobile
            this.$refs.addContract.getCustomer ();
        },
        addTask(){
            this.$refs.add_addClientTask.task_description = "";
            this.$refs.add_addClientWarranty.task_description = "";
            this.$refs.add_addClientTask.customerid = this.customerid;
            this.$refs.add_addClientWarranty.customerid = this.customerid;
        },
        addNote(){

            this.$refs.clientNotes.notes = '';
            this.$refs.clientNotes.customerid = this.customerid;
        },
        getCurrentCards(){
            this.getCustomerInfo()
        },
        getClients(){
            this.getCustomerInfo()
        },
        getCustomerInfo()
        {
            if(this.cusotmerid == 0){
                return false;
            }
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type' , 'getCustomerInfo');
            const auth = this.$cookies.get(this.$store.state.COOKIEPhase);
            post.append('auth',auth);
            post.append('data[customerid]',this.customerid)
            axios.post(
                this.$store.state.SAMCOTEC.r_path,
                    post
                ).then((response) => {
                    if(response && response.data){
                    this.getContracts()
                    const cus = response.data.results.data.customer;
                    const inv = response.data.results.data.invoices;
                    const receipt = response.data.results.data.receipt;
                    const cards = response.data.results.data.cards;
                    const cars = response.data.results.data.cars;
                    const quotes = response.data.results.data.quotes;
                    const notes = response.data.results.data.notes;
                    const tasks = response.data.results.data.tasks;
                    const warrantly = response.data.results.data.warrantly;
                    const calendar = response.data.results.data.calendar;
                    const periodCalendar = response.data.results.data.periodCalendar;
                    const Subscriber = response.data.results.data.Subscriber;
                    this.licensesAcc = response.data.results.data.renewal;
                    this.customer ={
                        id: cus.id,
                        customerid: cus.customerid,
                        mobile: cus.mobile,
                        mobile2: cus.mobile2,
                        customer_type: cus.customer_type,
                        full_name: cus.full_name,
                        company_name: cus.company_name,
                        crt_number: cus.crt_number,
                        vatid: cus.company_vatid,
                        address: cus.address,
                        entity_name: cus.entity_name,
                        branch_name: cus.branch_name,
                        exeute_date: cus.exeute_date,
                        contact_agent: cus.contact_agent,
                        procedure: cus.procedure,
                        client_priority: cus.client_priority,
                        _procedure: cus._procedure,
                        _proirity: cus._proirity,
                        agent: cus.agent,
                        executed_date: cus.executed_date,
                    }
                    this.invoices = inv;
                    this.payments = receipt;
                    this.cards = cards;
                    this.cars = cars;
                    this.quotes = quotes;
                    this.notes = notes;
                    this.tasks = tasks;
                    this.warrantly = warrantly;
                    this.calendar = calendar;
                    this.periodCalendar = periodCalendar;
                    this.Subscriber = Subscriber;
                    this.statistic.invoices = inv.length;
                    this.statistic.cards = cards.length;
                    this.statistic.cars = cars.length;
                    this.statistic.quotations = quotes.length;
                    this.finance.invoices = 0;
                    this.finance.paid = 0;
                    for(let i=0;i<inv.length;i++){
                        if(!isNaN(inv[i].ftotal)){
                            this.finance.invoices = parseFloat(this.finance.invoices) + parseFloat(inv[i].ftotal);
                        }
                        if(!isNaN(inv[i].paid)){
                            this.finance.paid = parseFloat(this.finance.paid) + parseFloat(inv[i].paid);
                        }
                    }
                    this.finance.cards = 0;
                    for(let i=0;i<cards.length;i++){
                        this.finance.cards = parseFloat(this.finance.cards) + parseFloat(cards[i].remain);
                    }
                    this.finance.remain = parseFloat(this.finance.invoices) - parseFloat(this.finance.paid)
                    this.finance.remain = parseFloat(this.finance.remain) + parseFloat(this.finance.cards)
                }
            });
            
        },
        Cards(id){
            this.getIt(id);
        },
        Invoices(id){
            this.getIt(id);
        },
        Payments(id){
            this.getIt(id);
        },
        Quots(id){
            this.getIt(id);
        },
        getIt(id){
            const btns = [
                'c_cards', 'c_invoices','c_payments','c_quotations'
            ];
            for(let i=0;i< btns.length;i++){
                if(btns[i] == id){
                    document.getElementById(btns[i]).classList.remove('c_lightgray');
                    document.getElementById(btns[i]).classList.add('c_black');
                }else{
                    document.getElementById(btns[i]).classList.remove('c_black');
                    document.getElementById(btns[i]).classList.add('c_lightgray');
                }
            }
            

        },
        getStatus(status){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , 'getStaticOptions');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[phrase]',status);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data.results.data;
                    for(let i=0;i<res.length;i++){
                        this.cusStatus.push({text: res[i][`name_`+this.lang.langname], value: res[i].value})
                    }
                }
            )
        },
    },
    created(){
        this.getStatus('customerStatus');
    }
}
</script>

<style>
.rowDiv{
    width:48%;
    margin-inline-end: 1%;
    padding:5px;
}
.rowDivFull{
    width:98%;
    margin-inline-end: 2%;
    margin-inline-start: 1%;
    padding:5px;
}
.myBtn{
    flex-wrap: wrap;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: stretch;
    margin-top:5px;
}
.mySubBtn{
    flex-wrap: wrap;
    display:none;
    flex-direction: row;
    justify-content: start;
    justify-items: center;
    align-items: center;
    margin-top:5px;
}
.btnRow{
    min-width:100px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    font-weight: bolder;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
    margin-inline-end:10px !important;
}
.btnRowF{
    min-width:120px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
}
.c_blue{
    background:blue !important;
    color:#fff !important;
}
.c_darkblue{
    background:darkblue !important;
    color:#fff !important;
}
.c_green{
    background:green !important;
    color:#fff !important;
}
.c_red{
    background:red !important;
    color:#fff !important;
}
.c_darkslategrey{
    background:darkslategrey !important;
    color:#fff !important;
}
.c_darkgreen{
    background:darkgreen !important;
    color:#fff !important;
}
.c_darkcyan{
    background:darkcyan !important;
    color:#fff !important;
}
.c_darkolivegreen{
    background:darkolivegreen !important;
    color:#fff !important;
}
.c_darkslateblue{
    background:darkslateblue !important;
    color:#fff !important;
}
.c_darkorange{
    background:darkorange !important;
    color:#fff !important;
}
.c_darkorchid{
    background:darkorchid !important;
    color:#fff !important;
}
.c_lightgray{
    background:#d1d1d1 !important;
    color:#000 !important;
}
.c_black{
    background:#000 !important;
    color:#fff !important;
}
.c_lightgray i{
    display:none;
}
</style>